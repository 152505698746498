import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'

type AssetType = {
  name: string
  publicURL: string
  childImageSharp: {
    fluid: FluidObject
  }
}

type AssetReturnType = {
  person: AssetType
  code: AssetType
}

const useGetAssets = () => {
  // change the regex parameter in the queries to switch between themes
  const assets: AssetReturnType = useStaticQuery(graphql`
    {
      person: file(name: { eq: "person" }, absolutePath: { regex: "/computer/" }) {
        name
        publicURL
        childImageSharp {
          fluid(quality: 50, maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      code: file(name: { eq: "code" }, absolutePath: { regex: "/computer/" }) {
        name
        publicURL
        childImageSharp {
          fluid(quality: 50, maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return { ...assets }
}

export { useGetAssets }
export type { AssetType }