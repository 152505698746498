import React from 'react'
import ParallaxImage from './ParallaxImage'
import Title from './Title'
import { AssetType } from './useGetAssets'
import cn from 'classnames'

type Props = {
  title: string
  assets: {
    background: AssetType
    foreground: AssetType
  }
  className?: string
}

const AtmoSphericHeroMobile: React.FC<Props> = ({
  title,
  assets,
  children,
  className
}) => {
  return (
    <div className={cn(className, 'd-block d-lg-none')}>
      <div className='mobile-atmo-hero pt-6 pb-5 position-relative'>
        <div className='mobile-atmo-hero__sky'>
          {assets.background && (
            <ParallaxImage mobile image={assets.background} />
          )}
        </div>
        <Title title={title} />
        <div className='mobile-atmo-hero__land'>
          {assets.foreground && (
            <img
              className='h-100 w-100 atmo-hero__land'
              src={assets.foreground.childImageSharp.fluid.src}
            />
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default AtmoSphericHeroMobile
