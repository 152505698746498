import { useEffect, useState } from 'react'

const useGetScrollRelativeToElement = (selector: string) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    const element = document.querySelector(selector)

    if (!element) return
    if (typeof window === undefined) return

    const end = window.innerHeight

    const getScrollPos = () => {
      const currentScroll = window.scrollY
      if (currentScroll >= 0 && currentScroll <= end) {
        setPercentage((currentScroll / end) * 300)
      } else if (currentScroll > end) {
        setPercentage(300)
      } else setPercentage(0)
    }

    window.addEventListener('scroll', getScrollPos, {
      passive: true,
      capture: false
    })
    return () => window.removeEventListener('scroll', getScrollPos)
  }, [])
  return percentage
}

export { useGetScrollRelativeToElement }
