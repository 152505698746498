import React, { useEffect, useState } from 'react'
import { Fade } from 'react-reveal'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import slugify from 'slugify'
import { ArticleFeature, ButtonDep as Button } from '@islandsstofa/react'
import TagFilter from '../TagFilter'
import { useSession } from '@src/context/sessionContext'
import { IArticleInterface } from '@src/data/prismic-resolvers/data'
import useGetArticles from '@src/hooks/useGetArticles'

export type ArticleArchiveProps = {
  useAllArticles: boolean
  limit?: number
  handpickedArticles?: IArticleInterface[]
  title: string
  category?: string
  description?: {
    html: string
    text: string
  }
  id: string
}

const getUniqueTags = (articles: IArticleInterface[]) => {
  const tagArray: string[] = []
  articles.forEach(article => {
    article.tags.forEach(tag => {
      if (!tagArray.includes(tag.toLocaleLowerCase())) {
        tagArray.push(tag.toLocaleLowerCase())
      }
    })
  })
  tagArray.sort((a, b) => {
    if (a > b) return 1
    if (a < b) return -1
    return 0
  })
  return tagArray
}

const ArticleArchive = ({
  useAllArticles,
  handpickedArticles = [],
  title,
  description,
  category,
  id,
  limit
}: ArticleArchiveProps) => {
  const data = useGetArticles()
  if (!data) return null

  const articles: IArticleInterface[] = useAllArticles
    ? data
    : handpickedArticles

  const { keys, updateKey, initKey } = useSession()
  const initialLoad = limit ?? 6
  const currentKey = keys.find(k => k.id === id)

  const tags = getUniqueTags(articles)

  const [chosenTags, setChosenTags] = useState<string[]>([])

  useEffect(() => {
    if (!currentKey) {
      initKey({ id, param: initialLoad })
    }
    if (category) {
      setChosenTags([category.toLowerCase()])
    }
  }, [])

  const filteredArticles =
    chosenTags.length > 0
      ? articles.filter(article =>
          article.tags.some(tag =>
            chosenTags.includes(slugify(tag, { lower: true }))
          )
        )
      : articles

  if (filteredArticles.length === 0) return null

  const updateChosenTags = (tag?: string) => {
    if (!tag) setChosenTags([])
    else {
      setChosenTags(prev => {
        if (prev.includes(tag)) {
          return prev.filter(t => t !== tag)
        } else {
          return [...prev, tag]
        }
      })
    }
  }

  return (
    <div className='article-archive container page-slice-spacer'>
      <div className='mt-3 mt-lg-0 mb-3 w-75'>
        <h2>{title ?? 'Articles'}</h2>
        {description && (
          <div
            className='parag--lg'
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
        )}
      </div>
      {!category && tags.length > 1 && (
        <div className='col-lg-6 pl-0'>
          <TagFilter
            allTags={tags}
            chosenTags={chosenTags}
            update={updateChosenTags}
          />
        </div>
      )}
      <div className='article-archive__grid'>
        {filteredArticles
          .slice(0, currentKey?.param ?? initialLoad)
          .map((a, i) => (
            <Fade
              spy={chosenTags}
              duration={500}
              left
              distance='5px'
              key={i}
              opposite
            >
              <Link to={a.url}>
                <ArticleFeature
                  title={a.title}
                  text={a.excerpt}
                  image={a.featuredImage}
                  tag={a.tags && a.tags.length > 0 ? a.tags : undefined}
                  tagType='white'
                  onRenderImage={() => (
                    <Img
                      fluid={a.featuredImage.fluid}
                      alt={a.featuredImage.alt}
                      className='h-100'
                    />
                  )}
                />
              </Link>
            </Fade>
          ))}
      </div>
      {currentKey?.param !== undefined &&
        currentKey.param < filteredArticles.length && (
          <Button
            onClick={() => updateKey({ id, param: initialLoad })}
            className='w-100'
            label='Load more'
            type='primary-inverted'
          />
        )}
    </div>
  )
}

export default ArticleArchive
