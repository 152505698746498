import { FluidObject } from 'gatsby-image'
import React from 'react'
import { Slider as CardSlider, ButtonDep as Button } from '@islandsstofa/react'
import { Link } from 'gatsby'
import Card from '../Card'

type Slide = {
  image: {
    url: string
    alt: string
    fluid: FluidObject
  }
  name: {
    text: string
  }
  excerpt: {
    html: string
  }
  url: string
}

export type SliderProps = {
  title: string
  link: {
    label: string
    url: string
  }
  slides: Slide[]
}

const Slider: React.FC<SliderProps> = ({ title, link, slides }) => {
  return (
    <section className='page-slice-spacer mt-5 mb-7'>
      <CardSlider
        cards={slides}
        title={title}
        visibleCards={3}
        onRenderLink={() => (
          <Link to={link.url}>
            <Button
              className='d-none d-lg-flex align-items-end'
              type='secondary'
              icon='arrowRight'
              label={link.label}
            />
          </Link>
        )}
        flickityOptions={{
          wrapAround: false,
          contain: true
        }}
        onRender={(card, idx) =>
          card.image && (
            <div key={idx} className='slider__slide px-lg-1'>
              <Link to={card.url || ''}>
                <Card
                  title={card.name.text}
                  text={card.excerpt}
                  image={card.image}
                ></Card>
              </Link>
            </div>
          )
        }
      />
    </section>
  )
}

export default Slider
