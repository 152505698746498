import React from 'react'
import { Fade } from 'react-reveal'
import { ButtonDep as Button } from '@islandsstofa/react'
import { Link } from 'gatsby'

type ContentProps = {
  text: {
    html: string
  }
  link: {
    url: string
  }
}

const Content = ({ text, link }: ContentProps) => {
  return (
    <div className='atmo-hero__content'>
      <div className='pl-lg-0 atmo-hero__content--parag'>
        <Fade up distance='25px' delay={750}>
          <div
            className='parag--lg mb-2 mb-lg-3 pb-3 pb-lg-0 col-lg-9 pl-0'
            dangerouslySetInnerHTML={{ __html: text.html }}
          />
        </Fade>
        <Fade delay={1000} up distance='25px'>
          <Link to={link.url} className='d-inline-flex'>
            <Button
              type='primary-inverted'
              label='Read more'
              icon='arrowRight'
              className='d-flex flex-row-reverse atmo-hero__content__button'
            />
          </Link>
        </Fade>
      </div>
    </div>
  )
}

export default Content
