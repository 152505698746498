import { useSession } from '@root/src/context/sessionContext'
import React, { useEffect } from 'react'
import Hero from './AtmosphericHero'
import MobileHero from './AtmosphericHeroMobile'
import Content from './Content'
import { AssetType, useGetAssets } from './useGetAssets'

type ThemeType = 'computer'

export type ControlPanelProps = {
  title: string
  text: {
    html: string
  }
  link: {
    url: string
  }
  theme: ThemeType
}

type ThemeOptions = {
  mobile: {
    foreground: AssetType
    background: AssetType
    middleLayer?: AssetType
  }
  desktop: {
    foreground: AssetType
    background: AssetType
    middleLayer?: AssetType
  }
}

const ControlPanel = ({ title, text, link, theme }: ControlPanelProps) => {
  const allAssets = useGetAssets()

  const themes: { [key in ThemeType]: ThemeOptions } = {
    computer: {
      desktop: {
        foreground: allAssets.person,
        background: allAssets.code
      },
      mobile: {
        foreground: allAssets.person,
        background: allAssets.code
      }
    }
  }

  const { initKey } = useSession()

  useEffect(() => {
    const navbarElement = document.querySelector('.navbar')
    if (navbarElement) {
      initKey({
        id: 'bg-style',
        param: 'white-font'
      })
    }
  }, [])

  if (!theme) {
    return null
  }

  return (
    <>
      {/* desktop version */}
      <Hero
        className='d-none d-lg-block'
        assets={themes[theme].desktop}
        title={title}
        theme={theme}
      >
        <Content text={text} link={link} />
      </Hero>

      {/* mobile version */}
      <MobileHero
        className='d-block d-lg-none'
        title={title}
        assets={themes[theme].mobile}
      >
        <Content text={text} link={link} />
      </MobileHero>
    </>
  )
}

export default ControlPanel
