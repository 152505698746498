import React from 'react'
import { Tag } from '@islandsstofa/react'
import slugify from 'slugify'
import cn from 'classnames'

type Props = {
  allTags: string[]
  chosenTags: string[]
  update: (tag?: string) => void
}

const TagFilter = ({ allTags, chosenTags, update }: Props) => {
  const updatedTags = allTags.map(tag => ({
    id: slugify(tag, { lower: true }),
    label: tag
  }))

  return (
    <div>
      <button
        onClick={() => update()}
        className={cn('tag-filter__button', {
          'tag-filter__button--selected': chosenTags.length === 0
        })}
      >
        <Tag type='white'>All</Tag>
      </button>
      {updatedTags.map((tag, key) => (
        <button
          key={key}
          onClick={() => update(tag.id)}
          className={cn('tag-filter__button', {
            'tag-filter__button--selected': chosenTags.includes(tag.id)
          })}
        >
          <Tag type='white'>{tag.label}</Tag>
        </button>
      ))}
    </div>
  )
}

export default TagFilter
