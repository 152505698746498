import React, { useState } from 'react'
import { useSearch } from '@islandsstofa/searchlib'
import { ResultUnit } from './components'
import { LoadingState, PaginationControls } from '@islandsstofa/react'

type SearchResultsProps = {
  query: string
  includeSites: string[]
}

const THIS_SITE = 'workiniceland'

const Head: React.FC = ({ children }) => {
  return (
    <div className='search-results__head pb-2'>
      <p>{children}</p>
    </div>
  )
}

const LIMIT = 10

const SearchResults = ({ query, includeSites = [] }: SearchResultsProps) => {
  const [pagination, setPagination] = useState(0)

  const { loading, error, value } = useSearch({
    query,
    lang: 'en',
    includeSites: [THIS_SITE, ...includeSites],
    limit: LIMIT,
    offset: pagination
  })
  if (error) return null
  if (loading) {
    return (
      <div className='search-results mt-3'>
        <Head>
          Loading results for: <strong>{query}</strong>
        </Head>
        <div className='search-results__results'>
          <div className='search-results__results__unit my-2 pt-2 pb-3 border-bottom position-relative'>
            {Array.from(Array(4)).map((_, key) => (
              <LoadingState key={key} />
            ))}
          </div>
        </div>
      </div>
    )
  }

  const nextPage = () =>
    setPagination(prev => {
      if (value) {
        const newValue = prev + LIMIT
        if (newValue < value.total) {
          if (typeof window !== undefined) {
            window.scrollTo(0, 0)
          }
          return newValue
        }
      }
      return prev
    })
  const prevPage = () =>
    setPagination(prev => {
      if (value) {
        const newValue = prev - LIMIT
        if (newValue >= LIMIT) {
          if (typeof window !== undefined) {
            window.scrollTo(0, 0)
          }
          return newValue
        }
      }
      return 0
    })

  const pages = value ? Math.floor(value?.total / LIMIT + 0.9) : 1

  return (
    <div className='search-results mt-3'>
      <Head>
        Search results for <strong>{query}</strong>: {value?.total}
      </Head>
      <div className='search-results__results'>
        {value?.results.map((unit, key) => (
          <ResultUnit
            key={key}
            isExternalLink={unit.document.site !== THIS_SITE}
            {...unit}
          />
        ))}
      </div>
      {value && value.total > 0 && (
        <PaginationControls
          prev={() => prevPage()}
          next={() => nextPage()}
          max={pages}
          goToPage={pageNumber => {
            const newValue = pageNumber * LIMIT
            setPagination(newValue)
          }}
          page={pagination / LIMIT}
        />
      )}
    </div>
  )
}

export default SearchResults
