import React, { useState } from 'react'
import { ButtonDep as Button } from '@islandsstofa/react'
import Img from 'gatsby-image'
import cn from 'classnames'
import { Link } from 'gatsby'
import { IArticleInterface } from '@src/data/prismic-resolvers/data'

export interface ArticleCollectionProps {
  heading: string
  description: {
    html: string
  }
  featuredArticle: IArticleInterface
  suggestedArticles: IArticleInterface[]
}

const Head = ({
  heading,
  description
}: {
  heading: string
  description: {
    html: string
  }
}) => {
  return (
    <div className='d-lg-flex justify-content-between mb-3'>
      <div className='mb-2 mb-lg-0'>
        <h2>{heading}</h2>
        <div
          className='parag--lg'
          dangerouslySetInnerHTML={{ __html: description.html }}
        />
      </div>
      <Link to='/articles' className='d-none d-lg-flex'>
        <Button type='secondary' icon='arrowRight' label='See all articles' />
      </Link>
    </div>
  )
}

const FeaturedArticle = ({
  featuredImage,
  className,
  title,
  excerpt,
  url
}: IArticleInterface & { className?: string }) => {
  return (
    <div className={cn('article-collection__featured-article', className)}>
      <div className='article-collection__featured-article__image image-rounded overflow-hidden mb-3'>
        <Img className='h-100 w-100' {...featuredImage} />
      </div>
      <h3>{title}</h3>
      {excerpt && (
        <div
          className='mb-3'
          dangerouslySetInnerHTML={{ __html: excerpt.html }}
        />
      )}
      <Link to={url}>
        <Button
          type='secondary'
          icon='arrowRight'
          className='d-flex'
          label='Read more'
        />
      </Link>
    </div>
  )
}

const SuggestedArticle = ({ featuredImage, title, url }: IArticleInterface) => {
  const [active, setActive] = useState(false)

  return (
    <Link
      to={url}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <div className='article-collection__suggested-article d-flex mb-2'>
        <div className='article-collection__suggested-article__image col-lg-5 px-0 image-rounded overflow-hidden'>
          <Img className='h-100 w-100' {...featuredImage} />
        </div>
        <div className='col-lg-7'>
          <h4
            className={cn(
              'mt-2 article-collection__suggested-article__title post-feature__title',
              {
                'post-feature__title--active': active
              }
            )}
          >
            <span>{title}</span>
          </h4>
        </div>
      </div>
    </Link>
  )
}

const ArticleCollection = ({
  heading,
  description,
  featuredArticle,
  suggestedArticles
}: ArticleCollectionProps) => {
  return (
    <div className='article-collection page-slice-spacer position-relative container mt-5'>
      <Head heading={heading} description={description} />
      <div className='d-lg-flex'>
        <FeaturedArticle
          className='col-lg-7 pl-0 pr-0 pr-lg-3 mb-4 mb-lg-0'
          {...featuredArticle}
        />
        <div className='col-lg-5 pl-0 article-collection__library'>
          {suggestedArticles.map((article, key) => (
            <SuggestedArticle key={key} {...article} />
          ))}
        </div>
      </div>
      <Link to='/articles' className='d-flex d-lg-none mt-4'>
        <Button
          type='secondary'
          icon='arrowRight'
          label='See all articles'
        ></Button>
      </Link>
    </div>
  )
}

export default ArticleCollection
