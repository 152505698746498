import React, { useState, useEffect } from 'react'
import { Icon } from '@islandsstofa/react'
import cn from 'classnames'

type Props = {
  placeholder: string
  items: {
    id: string
    label: string
  }[]
  onSelect: (item: { id: string; label: string }) => void
  size?: number
}

const Dropdown = ({ placeholder, items, onSelect, size = 8 }: Props) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!open) {
      const el = document.querySelector('.cf-dropdown')
      if (el) {
        el.scrollTo(0, 0)
      }
    }
  }, [open])

  return (
    <div
      className={cn('cf-dropdown', {
        'cf-dropdown--open': open
      })}
      style={open ? { height: `${50 * size}px` } : {}}
    >
      <button
        onClick={() => setOpen(!open)}
        className='px-2 cf-dropdown__item d-flex align-items-center justify-content-between'
      >
        <p>{placeholder}</p>
        <Icon type='arrowDown' />
      </button>

      {items.map((i, key) => (
        <button
          className='px-2 cf-dropdown__item'
          key={key}
          onClick={() => {
            onSelect(i)
            setOpen(false)
          }}
        >
          <p>{i.label}</p>
        </button>
      ))}
    </div>
  )
}

export default Dropdown
