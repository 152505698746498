import { FluidObject } from 'gatsby-image'
import { getTrimmedExcerpt } from '@islandsstofa/react'

export interface IPageInterface {
  url: string
  tags: string[]
  title: string
  featuredImage: {
    url: string
    alt: string
    fluid: FluidObject
  }
  excerpt: {
    html?: string
  }
}

export const pageResolver = (node: any): IPageInterface => {
  const content = node.data.body.find(
    (node: any) => node.slice_type === 'rich_text'
  )?.primary?.rich_text

  const image = node.data.body.find(
    (node: any) => node.slice_type === 'hero_media'
  )?.primary?.single_image

  return {
    tags: node.tags,
    title: node.data.page_title.text,
    url: node.url,
    excerpt: {
      html: getTrimmedExcerpt('p', content, 200)?.html || ''
    },
    featuredImage: image
  }
}

export interface IArticleInterface {
  prismicId: string
  url: string
  tags: string[]
  lang: string
  title: string
  shortTitle: string
  featuredImage: {
    url: string
    alt: string
    fluid: FluidObject
  }
  excerpt: {
    html: string
  }
  body: any[]
}

export const articleResolver = (node: any): IArticleInterface => ({
  prismicId: node.prismicId,
  url: node.url,
  tags: node.tags,
  lang: node.lang,
  title: node.data.title,
  shortTitle: node.data.short_title,
  featuredImage: node.data.featured_image,
  excerpt: node.data.excerpt,
  body: node.data.body
})
