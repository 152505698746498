import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { getTrimmedExcerpt } from '@islandsstofa/react'

export type CardType = {
  title: string
  text: {
    html: string
  }
  image: {
    url: string
    alt: string
    fluid: FluidObject
  }
  gap?: boolean
}

const Card = ({ title, text, image }: CardType) => {
  return (
    <div className='work-card pr-3'>
      <div className='work-card__inner h-100'>
        <div className='work-card__image overflow-hidden'>
          <Img className='h-100 w-100 image-rounded' {...image} />
        </div>
        <div className='work-card__text-box'>
          <h3>{title}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: getTrimmedExcerpt('p', text, 80)?.html ?? ''
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Card
