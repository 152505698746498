import System from '@cmp/sections/System'
import {
  SEO,
  SEOProps,
  HeroMedia,
  Feature,
  ThreeImages,
  ThreeImagesProps,
  HtmlSerializer,
  HeroMediaType,
  VideoSection,
  VideoSectionProps
} from '@islandsstofa/react'
import HeroHeader from '@cmp/site/HeroHeader'
import Embed from '@cmp/site/Embed'
import ArticleArchive, { ArticleArchiveProps } from '@cmp/site/ArticleArchive'
import Img from 'gatsby-image'
import React from 'react'
import AtmosphericHero, {
  ControlPanelProps
} from '@cmp/sections/AtmosphericHero'
import Slider from '@root/src/components/site/Slider'
import ArticleCollection, {
  ArticleCollectionProps
} from '@root/src/components/sections/ArticleCollection'
import { articleResolver } from '../prismic-resolvers/data'

export default {
  PrismicPageBodyHeroHeader: {
    component: HeroHeader,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image
        }
      } = data
      return {
        html,
        image,
        onRenderImage: () => <Img fluid={image.fluid} alt={image.alt} />
      }
    }
  },
  PrismicPageBodyArticlecollection: {
    component: ArticleCollection,
    dataToProps: (data: any): ArticleCollectionProps => ({
      heading: data.primary.collectionHeading,
      description: data.primary.descr ?? data.primary.description,
      featuredArticle: articleResolver(data.primary.featured_article.document),
      suggestedArticles: data.items
        .filter((item: any) => item.suggested_article.document)
        .map((item: any) => articleResolver(item.suggested_article.document))
    })
  },
  PrismicPageBodyVideoSection: {
    component: VideoSection,
    dataToProps: (data: any): VideoSectionProps => ({
      url: data.primary.url.url,
      description: data.primary.summary?.html ?? data.primary.description?.html,
      onRenderImage: () => (
        <Img
          fluid={data.primary.video_thumbnail.fluid}
          alt={data.primary.video_thumbnail.alt}
        />
      ),
      caption: data.primary.video_caption
    })
  },
  PrismicPageBodySlider: {
    component: Slider,
    dataToProps(data: any) {
      return {
        title: data.primary.title.text,
        link: {
          label: data.primary.link_label,
          url: data.primary.link?.document.url ?? data.primary.link.url
        },
        slides: data.items.map((item: any) => ({
          name: item.name,
          image: item.featured_image,
          excerpt: item.excerpt,
          url: item.page?.document?.url
        }))
      }
    }
  },

  PrismicPageBodySystem: {
    component: System,
    dataToProps(data: any) {
      return {
        programName: data.primary.program,
        options: data.items
          .filter(
            ({ parameter, value }: { parameter: string; value: string }) =>
              parameter && value !== null
          )
          .reduce((acc: any, item: any) => {
            acc[item.parameter] = item.value
            return acc
          }, {})
      }
    }
  },

  PrismicPageBodySeo: {
    component: SEO,
    dataToProps: (data: any): SEOProps => ({
      title: data.primary.override_title,
      description: data.primary.override_description,
      image: data.primary.override_image,
      keywords: data.primary.override_keywords
    })
  },
  PrismicPageBodyAtmosphericHero: {
    component: AtmosphericHero,
    dataToProps: (data: any): ControlPanelProps => ({
      title: data.primary.heroHeadline ?? data.primary.title,
      text: data.primary.text,
      link: data.primary.link,
      theme: 'computer'
    })
  },
  PrismicPageBodyRichText: {
    component: HtmlSerializer,
    dataToProps(data: any) {
      return {
        raw: data.primary.rich_text.raw
      }
    }
  },

  PrismicPageBodyHeroMedia: {
    component: HeroMedia,
    dataToProps(data: any): HeroMediaType {
      const {
        primary: {
          caption,
          single_image: singleImage,
          video,
          firstonpage: firstOnPage
        },
        items: gallery
      } = data
      return {
        caption,
        singleImage,
        video,
        gallery,
        firstOnPage,
        onRenderImageComponent: () => (
          <Img
            className='image-rounded'
            fluid={singleImage.fluid}
            alt={singleImage.alt}
          />
        )
      }
    }
  },

  PrismicPageBodyThreeImages: {
    component: ThreeImages,
    dataToProps(data: any): ThreeImagesProps {
      const images = [
        data.primary.first_image,
        data.primary.second_image,
        data.primary.third_image
      ]
      return {
        images: images.filter(image => image.url)
      }
    }
  },

  PrismicPageBodyArticleArchive: {
    component: ArticleArchive,
    dataToProps: (data: any): ArticleArchiveProps => {
      return {
        id: data.id,
        limit: data.primary.limit ?? 6,
        useAllArticles: data.primary.use_all_articles,
        title: data.primary.sliceTitle,
        category: data.primary.category,
        handpickedArticles: data.items
          .filter((item: any) => item.article.document)
          .map((item: any) => articleResolver(item.article.document))
      }
    }
  },

  PrismicPageBodyEmbed: {
    component: Embed,
    dataToProps(data: any) {
      return {
        title: data.primary.title,
        description: data.primary.description,
        video: data.primary.link_to_video.embed_url,
        singleImage: {
          url: data.primary.link_to_video.thumbnail_url,
          alt: data.primary.link_to_video.title
        }
      }
    }
  },
  PrismicPageBodyFeature: {
    component: Feature,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image
        }
      } = data

      return {
        html,
        image
      }
    }
  }
}
