import React, { useState } from 'react'
import { Input, Tag, Icon } from '@islandsstofa/react'
import SearchResults from './SearchResults'
import Dropdown from '@cmp/site/Dropdown'
import { navigate } from 'gatsby'

const availableSites: { id: string; label: string }[] = [
  { id: 'inspiredbyiceland.com', label: 'Inspired by Iceland' },
  { id: 'visiticeland.com', label: 'Visit Iceland' }
]

const Search = () => {
  const [query, setQuery] = useState('')
  const [includeSites, setIncludeSites] = useState<
    { id: string; label: string }[]
  >([])

  return (
    <div className='container page-slice-spacer mt-3 mt-lg-0 col-lg-8 px-lg-0 offset-lg-2 search'>
      <div className='d-flex justify-content-between'>
        <div>
          <h2>Search our sites</h2>
          <p className='parag--lg col-10 pl-0 col-lg-12'>
            Here you can search Work in Iceland or add our other sites to your search
          </p>
        </div>
        <button onClick={() => navigate(-1)}>
          <Icon type='close' />
        </button>
      </div>
      <div className='my-2 my-lg-3 search__controls'>
        <div className='search__controls__inputs'>
          <div>
            <p className='input-label'>Search</p>
            <Input
              onChange={e => setQuery(e.currentTarget.value)}
              placeholder='Type in keyword'
              containerClass='col-lg-9 pl-0'
            />
          </div>
          <div className='search__controls__inputs__dropdown'>
            <p className='input-label'>Other sites</p>
            <Dropdown
              placeholder='Add sites to search'
              onSelect={site =>
                setIncludeSites(prev => {
                  if (!prev.map(p => p.id).includes(site.id)) {
                    return [...prev, site]
                  } else return prev
                })
              }
              items={availableSites}
              size={availableSites.length + 1}
            />
          </div>
        </div>
        <div className='search__included-sites'>
          {includeSites.map((site, key) => (
            <button
              key={key}
              onClick={() =>
                setIncludeSites(prev => {
                  return prev.filter(prevSite => prevSite !== site)
                })
              }
              className='search__included-sites__tag'
            >
              <Tag type='black' className='d-flex'>
                {site.label} <Icon type='smallEx' />
              </Tag>
            </button>
          ))}
        </div>
      </div>

      {query.length > 2 ? (
        <SearchResults
          query={query}
          includeSites={includeSites.map(site => site.id)}
        />
      ) : null}
    </div>
  )
}

export default Search
