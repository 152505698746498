import React from 'react'
import { HeroMedia } from '@islandsstofa/react'
interface Props {
  title: { html: string; text: string }
  description: { html: string; text: string }
  video: string
  singleImage: {
    url: string
    alt: string
  }
}

const Embed = ({ title, description, video, singleImage }: Props) => {
  return (
    <div className='mb-6 mt-5 page-slice-spacer'>
      <HeroMedia video={{ url: video }} singleImage={singleImage} />
      <div className='d-flex flex-column align-items-center text-center'>
        <h2>{title.text}</h2>
        <p className='w-75 mt-2 parag--xl'>{description}</p>
      </div>
    </div>
  )
}
export default Embed
