import React, { useState } from 'react'
import { Document } from '@islandsstofa/searchlib'
import { Link } from 'gatsby'
import { Icon } from '@islandsstofa/react'
import cn from 'classnames'

type Props = {
  document: Document
  matchPct: number
  highlights: string[]
  isExternalLink: boolean
}

const ResultUnit = ({
  document,
  highlights,
  isExternalLink = false
}: Props) => {
  const LinkWrap: React.FC<{ activate: (b: boolean) => void }> = ({
    children,
    activate
  }) => {
    if (isExternalLink) {
      return (
        <a
          onMouseEnter={() => activate(true)}
          onMouseLeave={() => activate(false)}
          href={'https://' + document.site + document.path}
          target='_blank'
        >
          <div className='search-results__results__unit my-2 pt-2 pb-3 border-bottom position-relative'>
            <div className='search-results__results__unit__eyebrow d-flex align-items-center mb-1'>
              <span className='search-results__results__unit__eyebrow__ellipse' />
              <h6 className='search-results__results__unit__site mr-2 mt-1'>
                {document.site}
              </h6>
              <Icon
                className='search-results__results__unit__icon mb-1'
                type='externalLink'
              />
            </div>
            {children}
          </div>
        </a>
      )
    } else {
      return (
        <Link
          to={document.path}
          onMouseEnter={() => activate(true)}
          onMouseLeave={() => activate(false)}
        >
          <div className='search-results__results__unit my-2 pt-2 pb-3 border-bottom position-relative'>
            {children}
          </div>
        </Link>
      )
    }
  }

  const [active, setActive] = useState(false)

  const activate = (b: boolean) => setActive(b)

  return (
    <LinkWrap activate={activate}>
      <h4
        className={cn('mb-2 post-feature__title', {
          'post-feature__title--active': active
        })}
      >
        <span>
          {document.title === '' ? document.seoTitle : document.title}
        </span>
      </h4>
      <p
        dangerouslySetInnerHTML={{ __html: highlights[0] }}
        className={cn({
          'search-results__results__unit__text--active': active
        })}
      />
    </LinkWrap>
  )
}

export default ResultUnit
