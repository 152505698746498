import React, { useEffect, useState } from 'react'
import { Fade } from 'react-reveal'
import cn from 'classnames'
import { useGetScrollRelativeToElement } from '@root/src/hooks/useGetScrollRelativeToElement'
import { AssetType } from './useGetAssets'
import ParallaxImage from './ParallaxImage'
import Title from './Title'

export type AtmosphericHeroProps = {
  title: string
  theme: 'computer'
  assets: {
    background: AssetType
    middleLayer?: AssetType
    foreground: AssetType
  }
  className?: string
}

const AtmosphericHero: React.FC<AtmosphericHeroProps> = ({
  title,
  theme,
  children,
  className,
  assets
}) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <>
      <div
        className={cn(className, 'position-relative h-100 atmo-hero-spacer')}
      >
        <ParallaxContainer
          firstLayer={assets.background}
          secondLayer={assets.middleLayer}
        >
          <Title
            title={title}
            className={cn({
              'atmo-hero__title--computer': theme === 'computer'
            })}
          />
        </ParallaxContainer>

        <div className='white-overlay' />

        <div className='atmo-hero position-relative'>
          <div className='atmo-hero__land'>
            <Fade up distance='10px' delay={500} when={loaded}>
              <img
                className='h-100 w-100 atmo-hero__land'
                src={assets.foreground?.childImageSharp.fluid.src}
              />
            </Fade>
          </div>

          <div className='container'>{loaded && children}</div>
        </div>
      </div>
    </>
  )
}

type ParallaxContainerProps = {
  firstLayer: AssetType
  secondLayer?: AssetType
}

const ParallaxContainer: React.FC<ParallaxContainerProps> = ({
  children,
  firstLayer,
  secondLayer
}) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => setLoaded(true), [])

  const [passed, setPassed] = useState(false)

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener('scroll', () => {
        if (window.scrollY > window.innerHeight) setPassed(true)
        else setPassed(false)
      })
    }
  }, [])

  const perc = useGetScrollRelativeToElement('.atmo-hero')

  return (
    <>
      {firstLayer && (
        <ParallaxImage
          className={cn('atmo-hero__sky', {
            'atmo-hero__sky--hidden': passed
          })}
          image={firstLayer}
          transform={`translate3d(0, -${perc}px, 0)`}
        />
      )}

      {loaded && children}

      {secondLayer && (
        <ParallaxImage
          className={cn('atmo-hero__middleLayer', {
            'atmo-hero__middleLayer--hidden': passed
          })}
          image={secondLayer}
          transform={`translate3d(0, -${perc * 1.65}px, 0)`}
        />
      )}
    </>
  )
}

export default AtmosphericHero
