import React from 'react'
import { useGetScrollRelativeToElement } from '@root/src/hooks/useGetScrollRelativeToElement'
import cn from 'classnames'

type TitleProps = {
  title: string
  className?: string
}

const Title = ({ title, className }: TitleProps) => {
  const percentage = useGetScrollRelativeToElement('#atmo-hero__title')

  const transform = `translate3d(0, ${percentage}px, 0)`

  return (
    <div
      style={{
        transform
      }}
      id='atmo-hero__title'
      className={cn(className, 'atmo-hero__title')}
    >
      <div className='container'>
        <h1>{title}</h1>
      </div>
    </div>
  )
}

export default Title
