import React from 'react'
import { Container } from '@kogk/common'
import Img, { FluidObject } from 'gatsby-image'

interface Props {
  html: string
  image: {
    url: string
    alt: string
    fluid: FluidObject
  }
}

const HeroHeader = ({ html, image }: Props) => {
  return (
    <Container className='hero-header'>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        className='article hero-header__text'
      />
      <div className='col-lg-12 p-0'>
        <Img
          className='hero-header__image-container'
          alt={image.alt || ''}
          fluid={image.fluid}
        />
      </div>
    </Container>
  )
}

export default HeroHeader
