import React from 'react'
import { FilmGrainImage } from '@islandsstofa/react'
import { AssetType } from './useGetAssets'
import Img from 'gatsby-image'

const ParallaxImage = ({
  image,
  mobile = false,
  className = '',
  transform
}: {
  image: AssetType
  // High Definition will render a more expensive version
  mobile?: boolean
  className?: string
  transform?: string
}) => {
  if (!image) return null

  if (mobile) {
    return (
      <FilmGrainImage
        image={{
          url: image.publicURL,
          alt: ''
        }}
        canvasId='atmo-hero-canvas'
        intensity={25}
        onRenderImage={() => (
          <Img
            fluid={image.childImageSharp.fluid}
            alt='Atmospheric hero background'
          />
        )}
      />
    )
  }

  return (
    <div
      className={className}
      style={
        transform
          ? {
              transform
            }
          : {}
      }
    >
      <FilmGrainImage
        image={{
          url: image.publicURL,
          alt: ''
        }}
        canvasId='atmo-hero-canvas'
        intensity={25}
        className='h-100'
        onRenderImage={() => (
          <Img
            fluid={image.childImageSharp.fluid}
            alt='Atmospheric hero background'
          />
        )}
      ></FilmGrainImage>
    </div>
  )
}

const Memoized = React.memo(ParallaxImage)

export default Memoized
