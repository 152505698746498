import { graphql, useStaticQuery } from 'gatsby'
import '@src/data/prismic-fragments/article'
import {
  IArticleInterface,
  articleResolver
} from '../data/prismic-resolvers/data'

export default () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicArticle {
        nodes {
          ...prismicArticleFragmentFull
        }
      }
    }
  `)

  const articles: IArticleInterface[] = data.allPrismicArticle.nodes.map(
    (node: any) => articleResolver(node)
  )
  return articles
}
